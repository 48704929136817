export const authRoles = {
    sa: ['SA'], // Apenas o superadministrador tem acesso
    admin: ['SA', 'ADMIN'], // Apenas SA e Admin têm acesso
    editor: ['SA', 'ADMIN', 'EDITOR'], // Apenas SA e Admin e Editor têm acesso
    guest: ['SA', 'ADMIN', 'EDITOR', 'GUEST', 'USER'], // Todos têm acesso
    user: ['USER'],
    not: ['NAO']
}

// Verificação de saída app/views/dashboard/DashboardRoutes.js
// Apenas SA e Admin têm acesso ao painel

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <===============
//   }
// ];

// Verificar navigaitons.js

// {
//   name: "Dashboard",
//   path: "/dashboard/analytics",
//   icon: "dashboard",
//   auth: authRoles.admin <=================
// }
