import React from 'react'
import { authRoles } from '../../auth/authRoles'

const homeRoutes = [
    {
        path: '/aceiteAutomatico',
        component: React.lazy(() => import('./AppAceite')),
        auth: authRoles.guest,
    }
]

export default homeRoutes
