import React, {
    useContext,
    useEffect,
    useState,
} from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import AppContext from "app/contexts/AppContext";
import useAuth from 'app/hooks/useAuth'

const getUserRoleAuthStatus = (pathname, user, routes) => {
  const matched = routes.find((r) => r.path === pathname);

  const authenticated =
    matched && matched.auth && matched.auth.length
      ? matched.auth.includes(user.role)
      : true;
  console.log(matched, user);
  return authenticated;
};

const AuthGuard = ({ children }) => {
    const {
        isAuthenticated,
        user
    } = useAuth()

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()

    const { routes } = useContext(AppContext);
    console.log(user)
    let authenticated = false

    if(user != null){
        const isUserRoleAuthenticated = getUserRoleAuthStatus(pathname, user, routes);
        authenticated = isAuthenticated && isUserRoleAuthenticated;
    }else{
        authenticated = isAuthenticated
    }

    // SE VOCÊ PRECISA DE AUTENTICAÇÃO BASEADA EM FUNÇÃO,
    // DESCOMENDA ACIMA DE DUAS LINHAS, getUserRoleAuthStatus METHOD AND user VARIABLE
    // E COMENTAR ABAIXO DA LINHA
    // let authenticated = isAuthenticated

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])

    if (authenticated) return <>{children}</>
    else {
        return (
            <Redirect
                to={{
                    pathname: '/session/signin',
                    state: { redirectUrl: previouseRoute },
                }}
            />
        )
    }
}

export default AuthGuard
