import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: 'http://webservice.grupomariza.com.br:8080'
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Algo deu errado!'
        )
)

export default axiosInstance
